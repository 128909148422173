















































































































import store from "@/store";
import {Order} from "@/request/after-sale/Order";
import ClothingOrderExpand from '@/components/clothing-order-expand.vue';
import OrderStore from '@/views/after-sale/OrderStore';

export default {
    components: { ClothingOrderExpand },
    data() {
        let self: any =this;
        return {
            modal: Order,
            search: [
                {
                    type: 'Input',
                    label: '姓名',
                    key: 'name',
                    width: '120px',
                    placeholder: '请填写姓名'
                },
                {
                    type: 'Input',
                    label: '电话',
                    key: 'phone',
                    width: '140px',
                    placeholder: '请填写电话'
                },
                {
                    type: 'Input',
                    label: '订单号',
                    key: 'orderId',
                    width: '140px',
                    placeholder: '请填写订单号'
                },
                {
                    type: 'Select',
                    label: '支付方式',
                    key: 'payChannel',
                    clearable: true,
                    width: '120px',
                    items: [
                        {
                            label: '在线支付',
                            value: 0
                        },
                        {
                            label: '到店支付',
                            value: 1
                        }
                    ]
                },
                {
                    type: 'Select',
                    label: '支付状态',
                    key: 'status',
                    clearable: true,
                    width: '120px',
                    items: [
                        {
                            label: '等待支付',
                            value: 0
                        },
                        {
                            label: '支付成功',
                            value: 1
                        },
                        {
                            label: '支付失败',
                            value: 2
                        },
                        {
                            label: '订单超时',
                            value: 3
                        }
                    ]
                },
                {
                    type: 'DatePicker',
                    pickerType: 'daterange',
                    format: 'yyyy-MM-dd',
                    label: '日期',
                    width: '200px',
                    placeholder: '请选择日期'
                }
            ],
            hasNewOrder: false,
            hasNewOrderInterval: {},
            deliverModal: {
                show: false,
                loading: false,
                orderId: '',
                expressName: '',
                expressId: '',
                deliverDesc: '',
                bdeliver: false
            },
            columns: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h: any, p: any) => {
                        return h(ClothingOrderExpand, {
                            props: {
                                row: p.row
                            }
                        })
                    },
                    fixed: 'left'
                },
                {
                    title: '订单号',
                    key: 'orderId',
                    minWidth: 200
                },
                {
                    title: '订单状态',
                    key: 'status',
                    minWidth: 120,
                    render: (h: any, p: any) => {
                        let status  = p.row.status;
                        switch (status) {
                            case 0:
                                return h('p', '待支付');
                            case 1:
                                return h('p', '支付成功');
                            case 2:
                                return h('p', '支付失败');
                            case 3:
                                return h('p', '订单超时');
                            case 4:
                                return h('p', '取消成功');
                        }
                    }
                },
                {
                    title: '发货状态',
                    key: 'bdeliver',
                    minWidth: 100,
                    render: (h: any, p: any) => {
                        let self: any = this;
                        let bdeliver: boolean = p.row.bdeliver;
                        return h('Button', {
                            props: {
                                size: 'small',
                                disabled: bdeliver,
                                type: 'primary'
                            },
                            on: {
                                click() {
                                    self.preDeliver(p.row);
                                }
                            }
                        }, bdeliver? '已发货': '发货')
                    }
                },
                {
                    title: '订单总金额',
                    key: 'totalFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.totalFee))
                },
                {
                    title: '实付金额',
                    key: 'payFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.payFee))
                },
                {
                    title: '商品数量',
                    key: 'num',
                    minWidth: 120
                },
                {
                    title: '订单类型',
                    minWidth: 100,
                    render: (h: any, p: any) => h('p', p.row.bexchange? '兑换订单': '购买订单')
                },
                {
                    title: '订单时间',
                    key: 'createTime',
                    minWidth: 180
                },
                {
                    title: '交易门店',
                    key: 'store',
                    minWidth: 120
                },
                {
                    title: '购买人',
                    key: 'buyer',
                    minWidth: 120
                },
                {
                    title: '购买人电话',
                    key: 'buyerPhone',
                    minWidth: 140
                },
                {
                    title: '优惠券',
                    key: 'coupon',
                    minWidth: 120
                },
                {
                    title: '取货时间',
                    key: 'deliverTime',
                    minWidth: 180
                },
                {
                    title: '扫码员工',
                    key: 'deliver',
                    minWidth: 120
                },
                {
                    title: '支付时间',
                    key: 'payTime',
                    minWidth: 180
                },
                {
                    title: '取货方式',
                    key: 'deliveryType',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.deliveryType===0?'到店取货': '商家发货')
                },
                {
                    title: '使用积分',
                    key: 'useIntegral',
                    minWidth: 120
                },
                {
                    title: '获取积分',
                    key: 'getIntegral',
                    minWidth: 120
                },
                {
                    title: '支付方式',
                    key: 'payChannel',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.payChannel===0?'在线支付':'到店支付')
                },
                {
                    title: '优惠券抵扣',
                    key: 'couponFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.couponFee))
                },
                {
                    title: '活动优惠',
                    key: 'activityFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.activityFee))
                },
                {
                    title: '积分抵扣',
                    key: 'deductionFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.deductionFee))
                },
                {
                    title: '分享人',
                    key: 'shareName',
                    minWidth: 120
                },
                {
                    title: '分享人电话',
                    key: 'sharePhone',
                    minWidth: 140
                },
                {
                    title: '分享人门店',
                    key: 'shareStore',
                    minWidth: 160
                },
                {
                    title: '收货人电话',
                    key: 'receiverPhone',
                    minWidth: 140
                },
                {
                    title: '快递名称',
                    key: 'expressName',
                    minWidth: 140
                },
                {
                    title: '快递单号',
                    key: 'expressId',
                    minWidth: 160
                },
                {
                    title: '发货时间',
                    key: 'deliverTime',
                    minWidth: 180
                },
                {
                    title: '发货备注',
                    key: 'deliverDesc',
                    minWidth: 140,
                    tooltip: true
                }
            ],
            printObj: {
                id: "printMe",
                popTitle: '',
                extraCss: '',
                extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>',
                endCallback() {}
            },
            orderDetail: {
                goodsList: []
            }
        }
    },
    created(): void {
        let self: any = this;
        let info = sessionStorage.getItem(store.getters.info);
        if (info) {
            let infoObj: any = JSON.parse(info);
            self.printObj.popTitle = infoObj.merchantName;
        }
        clearInterval(self.hasNewOrderInterval);
        OrderStore.commit('hasNewOrder', false);
        self.hasNewOrderInterval = setInterval(() => self.hasNewOrder = OrderStore.getters.hasNewOrder, 1000)
    },
    methods: {
        preDeliver(row: any) {
            let self: any = this;
            self.deliverModal.orderId = row.orderId;
            self.deliverModal.show = true;
            self.deliverModal.expressName = row.expressName;
            self.deliverModal.expressId = row.expressId;
            self.deliverModal.deliverDesc = row.deliverDesc;
            self.deliverModal.bdeliver = row.bdeliver;
        },
        deliver() {
            let self: any = this;
            self.deliverModal.loading = true;
            Order.deliver({
                orderId: self.deliverModal.orderId,
                expressName: self.deliverModal.expressName,
                expressId: self.deliverModal.expressId,
                deliverDesc: self.deliverModal.deliverDesc
            }).then((body: any) => {
                self.deliverModal.loading = false;
                if (body.code === 0) {
                    self.$Message.info('成功');
                    self.flush();
                }
            })
        },
        print(row: any) {
            let self: any = this;
            self.orderDetail = row;
        },
        flush() {
            let self: any = this;
            self.hasNewOrder = false;
            OrderStore.commit('hasNewOrder', false);
            self.$refs['el-table'].flush();
        }
    }
}
