import {BaseRequest} from "@/request/BaseRequest";

class Order extends BaseRequest {

    public requestPath: string = '/after_sale/orders';

    /**
     * 发货/取货
     * @param param
     */
    deliver(param: {
        orderId: string,
        expressName: string,
        expressId: string,
        deliverDesc: string
    }): any {
        return this.request({
            url: `${this.requestPath}/deliver`,
            method: 'post',
            data: param
        })
    }

    /**
     * 新的未通知的订单数量(1.1新增)
     */
    notNoticeNum(): any {
        return this.get(`${this.requestPath}/notNoticeNum`)
    }

    /**
     * 获取订单详情
     * @param id
     */
    getDetailByOrderId(id: string): any {
        return this.post(`${this.requestPath}/detail`, {id: id})
    }

}

const c = new Order();
export {c as Order};