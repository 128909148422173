






































import {Order} from "@/request/after-sale/Order";

export default {
    name: "clothing-order-expand",
    props: {
        width: {
            type: Number
        },
        row: {
            type: Object
        }
    },
    data() {
        return {
            columns: [
                {
                    title: '图片',
                    key: 'image',
                    width: 100,
                    render: (h: any, p: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '80px',
                                width: '60px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: p.row.image
                            }
                        })
                    }
                },
                {
                    title: '商品名称',
                    key: 'name',
                    width: 200
                },
                {
                    title: '编码',
                    key: 'code',
                    width: 100
                },
                {
                    title: '规格1',
                    key: 'nameFirst',
                    width: 120,
                    render: (h: any, p: any) => {
                        let nameFirst: string = p.row.nameFirst;
                        let desc: string = p.row.desc;
                        if (nameFirst && desc) {
                            nameFirst += `(${desc})`;
                        }
                        return h('p', nameFirst?nameFirst: '')
                    }
                },
                {
                    title: '规格2',
                    key: 'nameSec',
                    width: 120,
                    render: (h: any, p: any) => {
                        let nameSec: string = p.row.nameSec;
                        let desc: string = p.row.secStandard?.desc;
                        if (nameSec && desc) {
                            nameSec += `(${desc})`;
                        }
                        return h('p', nameSec);
                    }
                },
                {
                    title: '数量',
                    key: 'num',
                    width: 80,
                    render: (h: any, p: any) => h('p', `数量：${p.row.num}`)
                },
                {
                    title: '价格',
                    key: 'price',
                    width: 120,
                    render: (h: any, p: any) => h('p', `${((p.row.secStandard?.price?p.row.secStandard?.price: 0) / 100).toFixed(2)}元`)
                },
                {
                    title: '参与活动',
                    key: 'activityName',
                    width: 160
                }
            ]
        }
    },
    created() {
        let self: any = this;
    }
}
