import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        hasNewOrder: false
    },
    getters: {
        hasNewOrder: state => state.hasNewOrder
    },
    mutations: {
        hasNewOrder: ((state, payload) => state.hasNewOrder=payload)
    },
    actions: {
    }
});

export default store;
